<template>
  <div class="container">
    <br>
    <el-table
      :data="list"
      border
      fit
      style="width: 50%"
    >
      <el-table-column
        :label="$t('body.certificate_query.title')"
      >
        <template slot-scope="scope">
          <el-link target="_blank" :href="scope.row.url">{{ scope.row.label }}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <br>
  </div>
</template>

<script>
export default {
  name: 'Certification',
  computed: {
    list: function() {
      return [
        { label: this.$t('body.certificate_query.srrc.label'), url: this.$t('body.certificate_query.srrc.url') },
        { label: this.$t('body.certificate_query.nal.label'), url: this.$t('body.certificate_query.nal.url') },
        { label: this.$t('body.certificate_query.ccc.label'), url: this.$t('body.certificate_query.ccc.url') },
        { label: this.$t('body.certificate_query.nmpa.label'), url: this.$t('body.certificate_query.nmpa.url') }
      ]
    }
  }
}
</script>

<style scoped>

</style>
